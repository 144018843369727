import React, { Component } from "react";
import styles from "./stylesheets/clients.module.css";
import { Grid, Row, Col } from "react-bootstrap";
import "./stylesheets/bootstrap/css/bootstrap.css";

class Clients extends Component {
  render() {
    return (
      <div id="clients" className={styles.clients}>
        <h1>CLIENTS</h1>
        <div className={styles.line} />
        <h2 className={styles.subtitle}>
          JOIN THESE SUCCESSFUL LAW FIRMS WHO ENTRUST SIGNIFICANT CASES TO
          <br />
          JOSHUA ANNENBERG AS APPELLATE & MOTION COUNSEL:
        </h2>
        <Grid>
          <Row>
            <Col sm={6} md={6}>
              <ul className={styles.column}>
                <li>ADAMS LAW FIRM, P.C.</li>
                <li>ALVIN BROOME & ASSOCIATES, P.C.</li>
                <li>BORNSTEIN & EMANUEL, P.C.</li>
                <li>PETER DEFILIPPIS & ASSOCIATES, P.C.</li>
                <li>DELLA MURA & CIACCI, LLP</li>
                <li>MICHAEL K. EIDMAN, ESQ.</li>
                <li>GERSHBAUM & WEISZ, P.C.</li>
                <li>GOIDEL & SIEGEL, LLP</li>
              </ul>
            </Col>
            <Col sm={6} md={6}>
              <ul className={styles.column}>
                <li>GREENBERG LAW, P.C.</li>
                <li>NEWMAN, ANZALONE & NEWMAN, LLP</li>
                <li>PECORARO & SCHIESEL, LLP</li>
                <li>RICH & RICH, P.C.</li>
                <li>ROTH & ROTH, LLP</li>
                <li>SCHWARTZ, GOLDSTONE, CAMPISI & KATES, LLP</li>
                <li>SEGAL LAW FIRM, P.C.</li>
              </ul>
            </Col>
          </Row>
        </Grid>
        <p className={styles.disclaimer}>
          Attorney Advertising. Past results do not guarantee future outcomes.
        </p>
      </div>
    );
  }
}

export default Clients;
