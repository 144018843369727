import React, { Component } from 'react';
import './App.css';
import Navigation from './components/navigation';
import Banner from './components/banner'
import About from './components/about';
import Appeals from './components/appeals';
import Motions from './components/motions';
import Clients from './components/clients';
import Contact from './components/contact';

class App extends Component {
  render() {
    return (
      <div>
        <Navigation/>
        <Banner/>
        <About/>
        <Appeals/>
        <Motions/>
        <Clients/>
        <Contact/>
      </div>
    );
  }
}

export default App;
