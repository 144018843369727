import React, { Component } from 'react';
import styles from './stylesheets/banner.module.css';
import portrait from './images/joshuaannenberg.jpg';
import { Grid, Row, Col } from 'react-bootstrap';
import './stylesheets/bootstrap/css/bootstrap.css';

class Banner extends Component {
    render() {
        return (
            <div className={styles.jumptarget} id="home">
                <Grid className={styles.banner}>
                    <Row>
                        <Col xs={12} md={4} className={styles.portrait_row}>
                            <img src={portrait} alt="Joshua Annenberg" className={styles.portrait}></img>
                        </Col>
                        <Col xs={6} md={8} className={styles.text}>
                            <h3 className={styles.joshua}>Joshua Annenberg, Esq.</h3>
                            <div className={styles.tagline}>
                                <h1>WHEN YOU MUST WIN.</h1>
                            </div>
                            <h3 className={styles.appellate}>APPELLATE & MOTION COUNSEL</h3>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Banner;