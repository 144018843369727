import React, { Component } from 'react';
import styles from './stylesheets/motions.module.css';
import { Grid, Row, Col, Collapse, Button } from 'react-bootstrap';
import './stylesheets/bootstrap/css/bootstrap.css';
import motions from '../json/motions.json';

class Motions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            button: 'Read More',
            width: window.innerWidth
        };

        this.generateMotions = this.generateMotions.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { visible } = this.state;
        const { width } = this.state;
        const isMobile = width <= 500;
        let numExamples = 6;
        if (isMobile) {
            numExamples = 3;
        }
        const examples = motions.slice(0, numExamples);
        const nonExamples = motions.slice(numExamples, undefined);
        return (
            <div className={styles.jumptarget} id="motions">
                <div className={styles.background}>
                    <div className={styles.motions}>
                        <h1>MOTIONS</h1>
                        <div className={styles.line}></div>
                        <Grid>
                            {this.generateMotions(examples)}
                            <Collapse in={this.state.visible}>
                                <div id="example-collapse-text">
                                    {this.generateMotions(nonExamples)}
                                </div>
                            </Collapse>
                        </Grid>
                        <div className={styles.more}>
                            <Button
                                className={styles.more_button}
                                onClick={() => {
                                    this.setState({ visible: !visible })
                                    if (!this.state.visible) {
                                        this.setState({ button: 'Read Less' });
                                    }
                                    else this.setState({ button: 'Read More' });
                                }}
                                aria-controls="example-collapse-text"
                                aria-expanded={visible}>
                                {this.state.button}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    generateMotions(appeals) {
        const jsx = [];
        for (let i = 0; i < appeals.length; i += 3) {
            const slice = appeals.slice(i, i + 3);
            jsx.push(
                <Row key={i} className={styles.examples}>
                    {slice.map((e, i) => {
                        return (
                            <Col key={i} md={3} lg={3} className={styles.example_body}>
                                <h1>{e.title}</h1>
                                <p className={styles.example_text}><i>{e.title}</i>{e.body}</p>
                            </Col>
                        );
                    })}
                </Row>
            );
        }
        return jsx;
    }
}

export default Motions;