import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import styles from './stylesheets/navigation.module.css';
import './stylesheets/bootstrap/css/bootstrap.css';
import logo from './images/janame.png';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevScroll: window.pageYOffset
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleScroll);
    }

    handleScroll() {
        var media = window.matchMedia("(max-width: 768px)")
        if(window.pageYOffset < 50) return;
        if(media.matches) {
            var currentScrollPos = window.pageYOffset;
            if (this.state.prevScroll > currentScrollPos) {
                document.getElementsByClassName("navbar")[0].style.top = "0";
            } else {
                document.getElementsByClassName("navbar")[0].style.top = "-50px";
            }
            this.setState({prevScroll: currentScrollPos});
        }
    }
    
    render() {
        return(
            <Navbar inverse collapseOnSelect className={styles.navbar}>
                <Navbar.Header>
                    <a href="/#home" className={styles.logo}><img src={logo} alt="Joshua Annenberg" className={styles.logo_image}></img></a>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        <NavItem eventKey={1} href="#home" className={styles.link}>
                            Home
                        </NavItem>
                        <NavItem eventKey={1} href="#about" className={styles.link}>
                            About
                        </NavItem>
                        <NavItem eventKey={1} href="#appeals" className={styles.link}>
                            Appeals
                        </NavItem>
                        <NavItem eventKey={1} href="#motions" className={styles.link}>
                            Motions
                        </NavItem>
                        <NavItem eventKey={1} href="#clients" className={styles.link}>
                            Our Clients
                        </NavItem>
                        <NavItem eventKey={2} href="#contact" className={styles.link}>
                            Contact Us
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Navigation;